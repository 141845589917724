import { AppAccordion, AppSwitch, withTooltip } from '@t4b/core'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Range, getTrackBackground } from 'react-range'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { FeedPlatformRuleEntity, MarkupType } from '../../../entity/feeding'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { addFeedPlatformSymbol, changeFeedPlatformSymbol, feedingConfigurationChanged, getFeedPlatformSymbols, updateFeedPlatformGap } from '../../../redux/actions/feeding-actions'
import { hideRightBar } from '../../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, cmselectInput, sselectInput, textInput } from '../../../utils/controls'
import { buildHTTPGetOptions, checkResponse, processError } from '../../../utils/fetch-utils'
import { buildMultiselectOptionsFromArray, convertFeedPlatform } from '../../../utils/multiselect-utils'
import { IRightbar } from '../rightbar-types'
import TextInput from '../../inputs/TextInput'
import { useLocation } from 'react-router-dom'
import { TableVirtuoso } from 'react-virtuoso'
import { FeedPlatformGapRow } from './FeedPlatformGapRow'
import { RootState } from '../../../redux/reducers/rootReducer'
import CheckBoxInput from '../../inputs/CheckBoxInput'
import { withTooltipPreventOverflow } from '../../ConfTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { useQuery } from '../../../hooks/useQuery'

const FeedPlatformSymbolRightbar: React.FC<IRightbar> = ({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const { ConnectorFilterPrices } = useSelector((state: any) => state.feedingConfiguration)
  const { feedPlatforms } = useSelector((state: any) => state.feedingConfiguration)
  const ref = useRef<any>(null)
  const location: any = useLocation()
  const { gateway } = useSelector((state: RootState) => state.gateways)

  const localState = JSON.parse(localStorage.getItem(`${useQuery().get('platform')} - ${useQuery().get('profile')}-feed`))
  const state = location.state ?? localState

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    new FeedPlatformRuleEntity({
      ...item,
      MarkupType: convertFeedPlatform(item.MarkupType),
      Multiplier: item.Multiplier ?? 1,
      IsDirection: item.Rounding !== 'Floor',
      Rounding: item.Rounding !== 'Floor' ? 'Ceil' : 'Floor',
      SpreadBalance: typeof item.SpreadBalance === 'string' ? [Number(item.SpreadBalance) + 1] : [1],
    }),
    FeedPlatformRuleEntity.schema,
  )
  const [platformSymbols, setPlatformSymbols] = useState<any>([])
  const [valid, setValid] = useState(true)
  const [, setRender] = useState(false)
  const platformTags = gateway?.Platforms?.find((item: any) => item.Name === state?.Platform)?.Tags ?? []

  const ConnectorFilterPrice = ConnectorFilterPrices?.find((item: any) => item.ConnectorName === state?.Platform)?.Prices
  const map: any = new Map()
  ConnectorFilterPrice?.forEach(({ Price, Symbol }: any) => {
    map.set(Symbol, Price)
  })
  const filterEnabled = feedPlatforms?.find((item: any) => item.Name === state?.Name)

  useEffect(() => {
    setRender(prev => !prev)
  }, [inputState.MarkupType.value])

  useEffect(() => {
    const url = new URL('/api/feedPlatform/symbols', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('feedPlatform', params.Platform)
    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => setPlatformSymbols(buildMultiselectOptionsFromArray(data)))
      .catch((error: Error) => processError(error, dispatch))
  }, [params, dispatch])

  const handleSave = () => {
    if (!isValid() || !valid) {
      if (ref.current) {
        ref.current.open()
      }
      return
    }

    const SpreadBalanceMap = inputState.SpreadBalance?.map((item: number) => item - 1)

    const newTrimState = {
      ...inputState,
      MarkupType: inputState.MarkupType.value,
      Symbols: inputState.Symbols.map((item: any) => {
        if (item.value.includes(',')) {
          return item.value.split(',')
        } else {
          return item.value
        }
      }).flat(Infinity),
    }

    let prices = []
    for (const [key, value] of map) {
      prices.push({ Symbol: key, Price: value })
    }

    if (type === 'add' || type === 'clone') {
      dispatch(updateFeedPlatformGap({ Name: state.Platform, Prices: prices }))
      dispatch(
        addFeedPlatformSymbol({
          params,
          body: { ...newTrimState, SpreadBalance: Number(SpreadBalanceMap.join('')), Rounding: inputState.Rounding !== 'Floor' ? 'Ceiling' : 'Floor' },
        }),
      )
    } else if (type === 'modify') {
      dispatch(updateFeedPlatformGap({ Name: state.Platform, Prices: prices }))
      dispatch(
        changeFeedPlatformSymbol({
          params,
          body: { ...newTrimState, SpreadBalance: Number(SpreadBalanceMap.join('')), Rounding: inputState.Rounding !== 'Floor' ? 'Ceiling' : 'Floor' },
        }),
      )
    }
    dispatch(
      getFeedPlatformSymbols({ ...newTrimState, SpreadBalance: Number(SpreadBalanceMap.join('')), Rounding: inputState.Rounding !== 'Floor' ? 'Ceiling' : 'Floor', ...params }),
    )
    dispatch(feedingConfigurationChanged())
    dispatch(hideRightBar())
  }

  const options = [
    {
      label: '---Symbols---',
      value: '1',
      options: platformSymbols,
    },
    {
      label: '---Tags---',
      value: '2',
      options: buildMultiselectOptionsFromArray(platformTags),
    },
  ]

  const controls = buildControlsExtTwoPerLine(
    [
      cmselectInput('Symbols', options || [], '', 'Mask', true).title('Add mask:'),
      textInput('MaxLevels'),
      sselectInput('MarkupType', convertFeedPlatform(buildMultiselectOptionsFromArray(Object.values(MarkupType)))).optionZindex(false),
      textInput('MarkupBid').skipWhen(inputState.MarkupType.value === MarkupType.Proportional),
      textInput('MarkupAsk').skipWhen(inputState.MarkupType.value === MarkupType.Proportional),
      textInput('MinSpread')
        .skipWhen(inputState.MarkupType.value === MarkupType.Fixed)
        .skipWhen(inputState.MarkupType.value === MarkupType.Proportional),
      textInput('MaxSpread').skipWhen(inputState.MarkupType.value !== MarkupType.MiniMax),
      textInput('Multiplier').skipWhen(inputState.MarkupType.value !== MarkupType.Proportional),
      checkboxInput('DeliveryToPlatformDisabled').setTooltip(
        true,
        'Use this mode to check supposed platform quotes in LP Performance; If checked, quotes from LP are received with applied markup but nothing is sent to platform.',
      ),
    ],
    inputState,
    setInputState,
    'feed-platform.symbol-map',
    touched,
    setTouched,
    errors,
  )

  const handleSwitch = (checked: boolean) => {
    setInputState({
      ...inputState,
      IsDirection: checked,
      Rounding: checked ? 'Ceil' : 'Floor',
    })
  }

  const switcher =
    inputState.MarkupType.value === MarkupType.Proportional ? (
      <div className="mr-3">
        <div className="mb-2 d-flex align-items-center justify-content-center">
          <span>Rounding: </span>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <span className="mr-2">Floor</span>
          <AppSwitch checked={inputState.IsDirection} onChange={handleSwitch} />
          <span className="ml-2">
            <FormattedMessage id="Ceiling" />
          </span>
        </div>
      </div>
    ) : null

  const range =
    inputState.MarkupType.value === MarkupType.Proportional ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          margin: '2em',
        }}
      >
        <span>Spread balance:</span>
        <div className="d-flex align-items-center justify-content-between" style={{ width: '100%' }}>
          <span>Bid</span>
          <span>Ask</span>
        </div>
        <Range
          values={inputState.SpreadBalance}
          step={1}
          min={0}
          max={2}
          onChange={values =>
            setInputState((prev: any) => {
              return {
                ...prev,
                SpreadBalance: values,
              }
            })
          }
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '5px',
                  width: '100%',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    values: inputState.SpreadBalance,
                    colors: ['#548BF4', '#ccc'],
                    min: 0,
                    max: 2,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '22px',
                width: '22px',
                borderRadius: '4px',
                backgroundColor: '#FFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 2px 6px #AAA',
              }}
            >
              <div
                style={{
                  height: '16px',
                  width: '5px',
                  backgroundColor: isDragged ? '#548BF4' : '#CCC',
                }}
              />
            </div>
          )}
        />
        <output style={{ marginTop: '30px' }} id="output"></output>
      </div>
    ) : null

  const wrapper = (
    <div className="d-flex flex-column position-relative">
      <div className="positionSwitch">{switcher}</div>
      <div style={{ width: '100%' }}>{range}</div>
    </div>
  )

  const onChange = (symbol: any, value: any) => {
    map.set(symbol, value)
  }

  const refTable: any = useRef(null)
  const newHeight = (ConnectorFilterPrice?.length ?? 0) * refTable?.current?.getBoundingClientRect()?.height
  const activeHeight = newHeight < window.innerHeight - ref?.current?.height().height - 430 ? newHeight : window.innerHeight - ref?.current?.height().height - 430

  const renderTooltip = (props?: any, tooltip?: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )

  const danger = (mode?: string) =>
    withTooltip(
      <span className="text-warning mr-2">
        <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'ffc107' }} />
      </span>,
      <FormattedMessage id="dangerFilterQuote" values={{ PlatformName: state.Name, Mode: mode }} />,
      Math.random(),
    )

  const filterPercent = (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <div className="mr-auto" style={{ width: 364 }}>
          <TextInput
            state={inputState}
            setState={setInputState}
            name="LimitationPercent"
            label="filterPercent.LimitationPercent"
            className="settings-block__field"
            errors={errors}
            touched={touched}
            setTouched={setTouched}
          />
        </div>
      </div>
      <div className="d-flex">
        {!filterEnabled?.FilterInvalidQuotesEnabled && (
          <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, '')}>
            {danger('Quote invalidation')}
          </OverlayTrigger>
        )}
        {withTooltipPreventOverflow(
          <div style={{ width: 200 }}>
            <CheckBoxInput state={inputState} setState={setInputState} name="FilterInvalidQuotes" label="filterPercent.FilterInvalidQuotes" className="settings-block__field" />
          </div>,
          `This mode filters zero and negative quotes`,
          `FilterInvalidQuotes`,
          '',
          'right',
        )}
      </div>

      <div className="AppVertTable">
        <tr className="tableTr">
          <th className="tableTh">
            <p>Symbol</p>
          </th>
          <th className="tableTh">
            <p>Last relevant price </p>
          </th>
        </tr>
        <TableVirtuoso
          style={{
            height: !ConnectorFilterPrice?.length ? 0 : activeHeight,
          }}
          data={ConnectorFilterPrice}
          itemContent={(index, item) => (
            <>
              <td className="tableTd" ref={refTable}>
                <Form.Control type="text" placeholder={'symbol'} readOnly value={item.Symbol} />
              </td>
              <FeedPlatformGapRow onChange={onChange} key={item.Symbol} index={index} symbol={item.Symbol} price={item.Price} setValid={setValid} />
            </>
          )}
        />
      </div>
    </>
  )

  const controlsQuotesFilter = buildControlsExtTwoPerLine(
    [textInput('SoftFilterPercent'), textInput('SoftFilterCount'), textInput('HardFilterPercent'), textInput('HardFilterCount')],
    inputState,
    setInputState,
    'feed-platform.symbol-map',
    touched,
    setTouched,
    errors,
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`symbol-map.${type}`} />,
          item: [controls, wrapper],
        }}
        ref={ref}
        isHidden={false}
      />
      <AppAccordion
        item={{
          title: !filterEnabled?.FilterEnabled ? (
            <>
              <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, '')}>
                {danger('Quote filtration')}
              </OverlayTrigger>
              <FormattedMessage id={`QuoteFilter.${type}`} />
            </>
          ) : (
            <FormattedMessage id={`QuoteFilter.${type}`} />
          ),
          item: controlsQuotesFilter,
        }}
        isHidden={true}
      />
      <AppAccordion
        item={{
          title: !filterEnabled?.LimitationEnabled ? (
            <>
              <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, '')}>
                {danger('Quote limitation')}
              </OverlayTrigger>
              <FormattedMessage id={`filterPercent.${type}`} />
            </>
          ) : (
            <FormattedMessage id={`filterPercent.${type}`} />
          ),
          item: filterPercent,
        }}
        isHidden={true}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
}

export default FeedPlatformSymbolRightbar
